import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;

  a {
    color: #0095eb;
  }

  p {
    margin: 50px 0;
  }

  ul {
    margin: 0 0 15px 30px;
    list-style: disc outside;
  }

  ol,
  li {
    margin-bottom: 10;
  }
`;

const TermsOfService = () => (
  <Layout>
    <SEO title="Privacy Policy" />

    <Container>
      {/* <div className="text-center text-5xl font-black mt-32 mb-20"> */}
      <div className="text-center text-5xl font-black mt-0 mb-20">
        Privacy Policy
      </div>
      <div>
        <p>
          <span className="font-normal">Last updated June 12, 2021</span>
        </p>
        <p>
          <span className="font-normal">
            Thank you for choosing to be part of our community at Volmex!{` `}
          </span>
        </p>
        <p> </p>
        <p>
          <span className="font-normal">
            This Privacy Policy applies to all information we collect from you
            (your “
          </span>
          <b>Personal Data</b>
          <span className="font-normal">
            ”) through our websites including{` `}
          </span>
          <a href="https://volmex.finance">
            <span className="font-normal">https://volmex.finance</span>
          </a>
          <span className="font-normal">
            , web applications, mobile applications, smart contracts,
            decentralized applications, APIs, all other software that Volmex has
            developed (collectively, the “
          </span>
          <b>App</b>
          <span className="font-normal">”). </span>
        </p>
        <p>
          <span className="font-normal">The Volmex Terms of Service (“</span>
          <b>Terms</b>
          <span className="font-normal">
            ”) are incorporated by reference into this Privacy Policy, and all
            capitalized terms (unless defined in this document) are defined in
            our Terms available at{` `}
          </span>
          <a href="https://www.volmex.finance/terms">
            <span className="font-normal">
              https://www.volmex.finance/terms
            </span>
          </a>
          <span className="font-normal">.</span>
        </p>
        <p> </p>
        <p>
          <span className="font-normal">
            If you have any questions or concerns about how we use your Personal
            Data, please email us at privacy@volmex.com.{` `}
          </span>
        </p>
        <ul>
          <li aria-level={1}>
            <b>WHAT PERSONAL DATA DO WE COLLECT?</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We collect Personal Data from you when you use our website, sign up
            for our newsletter, or contact us. We also receive Personal Data
            from analytic provider Google Analytics. The Personal Data we
            collect from you may include:
          </span>
        </p>
        <p> </p>
        <ul>
          <li className="font-normal" aria-level={1}>
            <span className="font-normal">Email address.</span>
          </li>
          <li className="font-normal" aria-level={1}>
            <span className="font-normal">
              Cookies, web tracking, and analytics.{` `}
            </span>
          </li>
        </ul>
        <p>
          <b />
        </p>
        <ul>
          <li aria-level={1}>
            <b>HOW DO WE USE YOUR PERSONAL DATA?</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We use your Personal Data for the following business purposes:
          </span>
        </p>
        <p>
          <b />
        </p>
        <ul>
          <li aria-level={1}>
            <b>Communicate with you. </b>
            <span className="font-normal">
              We use your Personal Data to contact you about changes to our
              website, Terms, Privacy Policy and updates to our App, provide
              customer support, and send you our newsletter.
            </span>
          </li>
        </ul>
        <ul>
          <li className="font-normal" aria-level={1}>
            <b>Enforce our Terms. </b>
            <span className="font-normal">
              We may use your Personal Data to assist with enforcement of our
              Terms (available at{` `}
            </span>
            <a href="https://www.volmex.finance/terms">
              <span className="font-normal">
                https://www.volmex.finance/terms
              </span>
            </a>
            <span className="font-normal">
              ), this Privacy Policy, and other agreements.
            </span>
          </li>
        </ul>
        <ul>
          <li aria-level={1}>
            <b>Respond to legal requests and prevent harm.</b>
            <span className="font-normal">
              {` `}
              If we receive a subpoena or other legal request, we may need to
              inspect the information we hold to determine how to respond.{` `}
            </span>
          </li>
        </ul>
        <ul>
          <li className="font-normal" aria-level={1}>
            <b>Analytics. </b>
            <span className="font-normal">
              When you browse or use our website, we utilize commonly-used
              logging and analytics tools such as Google Analytics to collect
              information about your device, the network used to access the
              website, and information about your use of the website (such as
              how you navigate and move around the website).
            </span>
          </li>
        </ul>
        <p>
          <b />
        </p>
        <ul>
          <li aria-level={1}>
            <b>WITH WHOM WILL WE SHARE YOUR PERSONAL DATA?</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We only share and disclose your Personal Data with the following
            third parties:
          </span>
        </p>
        <p> </p>
        <ul>
          <li style={{ listStyleType: `none` }}>
            <ul>
              <li className="font-normal" aria-level={1}>
                <b>Purchasers of Our Business.</b>
                <span className="font-normal">
                  {` `}
                  We may share your Personal Data in connection with any merger
                  or acquisition of our business to another company.
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li aria-level={1}>
            <b>Law Enforcement. </b>
            <span className="font-normal">
              We may share your Personal Data in order to comply, as necessary,
              with applicable laws and regulatory requirements.{` `}
            </span>
          </li>
        </ul>
        <ul>
          <li aria-level={1}>
            <b>HOW LONG DO WE KEEP YOUR PERSONAL DATA?</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We keep your Personal Data to the extent we deem necessary to carry
            out the processing activities described above or until you ask us to
            delete them.
          </span>
        </p>
        <p>
          <b />
        </p>
        <ul>
          <li aria-level={1}>
            <b>HOW DO WE KEEP YOUR PERSONAL DATA SAFE?</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We ensure that our systems are secure and that they meet industry
            standards. We seek to protect your Personal Data by implementing
            physical and electronic safeguards. Any service provider we engage
            with will have similar security and confidentiality policies. We
            will do our best to protect the security of your Personal Data but
            as you know, no security system is completely secure.
          </span>
          <b> </b>
        </p>
        <ul>
          <li aria-level={1}>
            <b>DO WE COLLECT PERSONAL DATA FROM MINORS?</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            You must be at least 18 years old to use the App. We do not
            knowingly collect Personal Data from persons under 13 years of age.
            If we learn that we have collected Personal Data about a person
            under the age of 13 years of age, we will deactivate the account and
            delete such data from our records. If you become aware of any
            information we have collected from a person under the age of 13,
            please email us at privacy@volmex.com.{` `}
          </span>
        </p>
        <ul>
          <li aria-level={1}>
            <b>USE OF GOOGLE ANALYTICS</b>
          </li>
        </ul>
        <p>
          <span className="font-normal">
            We use Google Analytics. The following link explains how Google uses
            data when you use its partners’ websites and applications:{` `}
          </span>
          <a href="http://www.google.com/policies/privacy/partners/">
            <span className="font-normal">
              www.google.com/policies/privacy/partners/
            </span>
          </a>
          <span className="font-normal">
            . Your use of our website is evidence of your consent to us storing
            and accessing cookies and other information on your computer or
            phone and our use of Google Analytics in connection with such
            activities. Please read the information at the link provided so you
            understand what you are consenting to.
          </span>
        </p>
        <p>
          <span className="font-normal">
            Please note that blocking, deleting, or restricting cookies may
            negatively affect the performance or certain functionality of the
            website. If you would like to opt out of Google Analytics, and
            certain Google advertising functionality, you may do so using
            Google’s opt-out tool.
          </span>
        </p>
        <ul>
          <li aria-level={1}>
            <b>CONTROLS FOR DO-NOT-TRACK FEATURES</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We do not currently respond to “Do Not Track” or DNT browser signals
            or any other mechanism that automatically communicates your choice
            not to be tracked online.{` `}
          </span>
        </p>
        <ul>
          <li aria-level={1}>
            <b>
              PRIVACY RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION
              (“GDPR”)
            </b>
          </li>
        </ul>
        <p>
          <span className="font-normal">
            If you are a resident of the European Economic Area (“
          </span>
          <b>EEA</b>
          <span className="font-normal">”) or Switzerland (“</span>
          <b>EEA Residents</b>
          <span className="font-normal">
            ”), you can make the following requests at any time by emailing{` `}
          </span>
          <a href="mailto:privacy@volmex.com">
            <span className="font-normal">privacy@volmex.com</span>
          </a>
          <span className="font-normal">.</span>
        </p>
        <ul>
          <li className="font-normal" aria-level={1}>
            <b>Opt-Out.</b>
            <span className="font-normal">
              {` `}
              You may request that we stop sending you direct marketing
              communications which you have previously consented to receive. We
              may continue to send you service-related and other non-marketing
              communications.
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Access.</b>
            <span className="font-normal">
              {` `}
              You may request we provide you with information about our
              processing of your Personal Data and give you access to your
              Personal Data.
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Rectify.</b>
            <span className="font-normal">
              {` `}
              You may request we update or correct inaccuracies in your Personal
              Data.
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Erase.</b>
            <span className="font-normal">
              {` `}
              You may request we erase your Personal Data.{` `}
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Export.</b>
            <span className="font-normal">
              {` `}
              You may request we transfer a machine-readable copy of your
              Personal Data to you or a third party of your choice.
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Restrict.</b>
            <span className="font-normal">
              {` `}
              You may request we restrict the processing of your Personal Data
              at any time. For example, you may request that we stop storing
              your email.{` `}
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Object.</b>
            <span className="font-normal">
              {` `}
              You may object to our processing of your Personal Data at any
              time. We have provided you with the ability to unsubscribe if you
              do not wish to receive newsletters or marketing emails from us
              anymore.{` `}
            </span>
          </li>
        </ul>
        <p>
          <span className="font-normal">
            Note that we may refuse to grant your requests in whole or in part
            as permitted by the GDPR. You have the right to complain to a data
            protection authority about our collection and use of your Personal
            Data. For more information, please contact your local data
            protection authority or{` `}
          </span>
          <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
            <span className="font-normal">click here</span>
          </a>
          <span className="font-normal">.</span>
        </p>
        <ul>
          <li aria-level={2}>
            <b>Our basis for processing your Personal Data</b>
          </li>
        </ul>
        <p>
          <span className="font-normal">
            The GDPR prohibits us from processing or using your Personal Data
            unless certain conditions apply. These conditions are known as
            “legal basis” and we will usually rely on one of the following legal
            bases when processing your Personal Data:
          </span>
        </p>
        <ul>
          <li className="font-normal" aria-level={1}>
            <b>Contract</b>
            <span className="font-normal">
              . We use your Personal Data because you have entered into a
              contract with us and have accepted our Terms.{` `}
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Legitimate Interest. </b>
            <span className="font-normal">
              We use your Personal Data because we have a legitimate interest in
              doing so. For example, we need to use your Personal Data to
              communicate with you as necessary to provide the App and to
              improve our services.{` `}
            </span>
          </li>
          <li className="font-normal" aria-level={1}>
            <b>Consent. </b>
            <span className="font-normal">
              We use your Personal Data because you have given your consent to
              us to use your Personal Data for specific purposes. You can revoke
              your consent at any time.{` `}
            </span>
          </li>
        </ul>
        <ul>
          <li aria-level={2}>
            <b>International Transfers of Personal Data</b>
          </li>
        </ul>
        <p>
          <span className="font-normal">
            We will store and process your Personal Data in the United States.
            If you use the App from outside the United States, you acknowledge
            we will transfer your Personal Data to, and store your Personal Data
            in, the United States, which may have different data protection
            rules than in your country, and Personal Data may become accessible
            as permitted by law in the United States, including to law
            enforcement and/or national security authorities in the United
            States.{` `}
          </span>
        </p>
        <ul>
          <li aria-level={1}>
            <b>CHANGES TO OUR PRIVACY POLICY</b>
          </li>
        </ul>
        <p> </p>
        <p>
          <span className="font-normal">
            We reserve the right to make changes to this Privacy Policy. If we
            make material changes to our Privacy Policy, our revised Privacy
            Policy will be posted at{` `}
          </span>
          <a href="https://www.volmex.finance/privacy">
            <span className="font-normal">
              https://www.volmex.finance/privacy
            </span>
          </a>
          <span className="font-normal">
            , and it will either be noted on our App that material changes have
            been made or we will notify our users by email. The date of the most
            recent update to our Privacy Policy will be set forth in the header
            to the Privacy Policy.
          </span>
        </p>
        <ul>
          <li aria-level={1}>
            <b>HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?</b>
          </li>
        </ul>
        <p>
          <span className="font-normal">
            If you have questions or concerns about our Privacy Policy
            practices, you may email us at privacy@volmex.com.
          </span>
        </p>
      </div>
    </Container>
  </Layout>
);

export default TermsOfService;
